.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--gray200);
  box-shadow: 0 0 5px var(--black020);
}

.description {
  margin: 0;
}
