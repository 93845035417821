.container {
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1em;
}

.text {
  &.isHighlighted {
    font-weight: bold;
    text-decoration: underline;
  }
}

.icon {
  margin-left: 0.5em;
  font-size: var(--iconXS);
}
