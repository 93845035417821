.accordionWrapper {
  margin-bottom: var(--spacing1000);
  & .expenseTypesWrapper {
    padding: var(--spacing1000);
    background: var(--white);
    & .categoryWrapper {
      &:not(:last-child) {
        margin-bottom: var(--spacing1000);
      }
      & .expenseTypeWrapper {
        margin-top: var(--spacing0500);
      }
    }
  }
}

@media screen and (--min-width-lg) {
  .modal {
    width: 650px;
  }
}
