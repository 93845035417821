.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background-color: var(--gray200);
  box-shadow: 0 0 5px var(--black020);
}

.icon {
  font-size: 1.5rem;
}
