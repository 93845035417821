.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow2);
  border: 2px solid transparent;
  outline: 1px solid var(--gray400);
  padding: var(--spacing1000);
  transition: border-color 0.25s ease-out, background-color 0.25s ease-out;
  cursor: pointer;

  &.checked {
    border-color: var(--primaryBg);
    background-color: var(--gray200);
  }
}

.input {
  display: none;
}

.icon {
  margin-bottom: var(--spacing0500);
  font-size: var(--iconXL);
  color: var(--primaryText);
}
