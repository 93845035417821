.readonlyValue {
  text-align: center;
  min-height: 1px;
}
.tooltip {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
