.slideUp {
  animation-name: slideUp;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.pageContent {
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container {
  composes: slideUp;
  display: block;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.container.configuring {
  background: var(--primaryBgLight);
  overflow: hidden;
  & .footer {
    display: none;
  }
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--white);
  background: var(--gray800);
  flex-shrink: 0;
  height: 58px;
}

.footerLinks {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing1000);
}

.disclaimerText {
  margin: var(--spacing2000) 0;
}

.poweredBy {
  height: 42px;
  position: absolute;
  left: var(--spacing1000);
  top: 50%;
  transform: translateY(-50%);
  fill: var(--white);
}

.eulaModalContent {
  padding: 0;
}

.configuringHeader {
  background: var(--gray800);
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing0750);
}

.scopeSelectorWrapper {
  @media screen and (--max-width-md) {
    min-width: 240px;
  }
  @media screen and (--max-width-sm) {
    min-width: 160px;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    margin-top: var(--spacing1500);
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@media screen and (--max-width-md) {
  .scopeExplanation {
    display: none;
  }
  .editBtn {
    display: none;
  }
}

@media screen and (--max-width-sm) {
  .footer {
    height: auto;
    text-align: center;
    padding: var(--spacing1000) var(--spacing0500);
    flex-direction: column;
  }
  .poweredBy {
    position: relative;
    margin-bottom: var(--spacing0250);
    top: unset;
    left: unset;
    transform: none;
  }
}
