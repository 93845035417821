.header,
.section {
  display: flex;
  border-bottom: 1px solid var(--gray300);
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--gray200);
  z-index: 1;

  & > * {
    padding: 0 1rem;
  }
}

.section {
  & > * {
    padding: 1rem;
  }

  &:last-child {
    border-bottom: none;
  }
}

.leftColumn {
  flex-shrink: 0;
  width: 180px;
  overflow: auto;
}

.middleColumn {
  flex-grow: 1;
  overflow-x: auto;
  border-left: 1px solid var(--gray300);
  border-right: 1px solid var(--gray300);
}

.rightColumn {
  display: flex;
  flex-shrink: 0;
  width: 340px;
  flex-direction: column;
  &.short {
    width: 150px;
  }
}

.segmentHead,
.segmentList {
  display: flex;
  align-items: center;
  height: 50px;

  & > * {
    width: 200px;
    flex-shrink: 0;
  }
}

.segmentHead {
  gap: var(--spacing0500);
  & > * {
    text-align: center;
    font-weight: bold;
  }
}

.segmentList {
  gap: var(--spacing0750);
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
}

.actionList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  gap: var(--spacing0500);
}

.invalidDistributionError {
  margin-top: var(--spacing0250);
  color: var(--blockingRed);
}
