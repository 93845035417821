.statusContainer {
  display: flex;
  align-items: center;
}

.complianceWarning {
  visibility: hidden;
  margin-right: 0.5rem;
}

.visible {
  visibility: visible;
}

.warn {
  font-size: 1.1rem;
  color: var(--alertOrange);
}
