.counter {
  margin: var(--spacing1000) 0;
}

.billingAmount {
  font-weight: 700;
}

.assignee {
  display: flex;
  flex-direction: row;
  gap: var(--spacing0500);
  align-items: center;
}
