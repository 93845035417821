.list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing0750);
  margin: 0;
  padding: 0;
}

.attendee {
  display: flex;
  height: 34px;
  padding: 0 var(--spacing0750);
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--gray300);
}

.attendeeIcon {
  display: inline-flex;
  margin-right: 5px;
}

.removeButton {
  margin-left: var(--spacing0750);
}

.form {
  display: flex;
}

.addButton {
  margin-left: var(--spacing0750);
}
