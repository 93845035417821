.pageContent {
  display: flex;
  padding: 0.7em 0 0;
  background: var(--gray300);
}

.emptyContainer {
  display: flex;
  flex: 1;
}

.tabContainer {
  flex-grow: 1;
  box-shadow: none;
  & .tabcontent {
    padding-bottom: var(--spacing4000);
  }
}

.actionButton {
  margin: 0;
}
