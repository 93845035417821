.counter {
  margin: var(--spacing1000) 0;
}

.pageContent {
  display: flex;
  flex-direction: column;
}
.assignee {
  display: flex;
  flex-direction: row;
  gap: var(--spacing0500);
  align-items: center;
}
