.container {
  display: flex;
  align-items: flex-start;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid var(--gray500);
  background-color: var(--white);
  transition: border 0.25s ease-out, background-color 0.25s ease-out;
  cursor: pointer;
  flex-shrink: 0;
  &:focus {
    outline: 1px solid var(--primaryText);
    outline-offset: 1px;
  }

  & .icon {
    fill: var(--white);
    width: 66.66%;
  }

  &.isChecked {
    background-color: var(--primaryBg);
    border-color: var(--primaryBg);
    & .icon {
      fill: var(--textOnPrimaryBg);
    }
  }

  &:hover {
    border-color: var(--primaryText);
    &.isDisabled {
      cursor: not-allowed;
    }
    &.isChecked {
      background-color: var(--primaryBgHover);
      border-color: var(--primaryBgHover);
      &.isInvalid {
        background-color: var(--blockingRedDark);
        border-color: var(--blockingRedDark);
      }
    }
  }

  &.isDisabled {
    border-color: var(--gray400);
    &.isChecked {
      background-color: var(--gray400);
    }
  }

  &.isInvalid {
    border-color: var(--blockingRed);
    &.isChecked {
      background-color: var(--blockingRed);
    }
  }
}

.defaultCheckbox {
  display: none;
}

.label {
  margin-left: var(--spacing0500);
  &.isDisabled {
    color: var(--ink400);
  }
}
