.item {
  background-color: var(--white);
  margin-bottom: 2rem;
  box-shadow: 0 0 5px var(--black020);
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--gray200);
  border-bottom: 1px solid var(--gray300);

  &.withTopBorder {
    border-top: 4px solid var(--primary);
  }
}

.expandIcon {
  transition: transform 0.25s ease-out;

  &.isRotated {
    transform: rotate(-90deg);
  }
}

.itemIndex {
  font-size: 1.5rem;
  color: var(--ink600);
  font-weight: 600;
}

.expenseTypeField {
  flex-grow: 1;
}

.content {
  padding: 1rem;
}

.specificForm {
  margin-bottom: 2rem;
}

.footerLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 1px solid var(--gray300);

  & > * {
    flex-basis: 0;
    flex-grow: 1;

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.notCompliantIcon {
  color: var(--alertOrange);
  font-size: 1.5rem;
}

@media screen and (--max-width-md) {
  .header {
    z-index: unset;
  }
}
