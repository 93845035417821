.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
}

.stickyAtBottom {
  position: sticky;
  bottom: 0;
  margin: 0 -2rem -2rem;
  padding: 1rem 2rem;
}
