/* AUTO-GENERATED FILE.  DO NOT MODIFY. */
@charset "UTF-8";

@font-face {
  font-family: 'neoicons';
  src: url('./neoicons.eot?#iefix') format('embedded-opentype'),
    url('./neoicons.woff') format('woff'),
    url('./neoicons.ttf') format('truetype'),
    url('./neoicons.svg#neoicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: neoicons !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*='icon-'] {
  display: inline-flex;
  vertical-align: middle;
}

[class^='icon-']:before,
[class*='icon-']:before {
  font-family: 'neoicons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-amazon::before {
  content: '\ea01';
}

.icon-amex::before {
  content: '\ea02';
}

.icon-approve::before {
  content: '\ea03';
}

.icon-archived::before {
  content: '\ea04';
}

.icon-arrowLeft::before {
  content: '\ea05';
}

.icon-arrowRight::before {
  content: '\ea06';
}

.icon-attach::before {
  content: '\ea07';
}

.icon-back::before {
  content: '\ea08';
}

.icon-ban::before {
  content: '\ea09';
}

.icon-budget::before {
  content: '\ea0a';
}

.icon-building::before {
  content: '\ea0b';
}

.icon-burger::before {
  content: '\ea0c';
}

.icon-calendar::before {
  content: '\ea0d';
}

.icon-car::before {
  content: '\ea0e';
}

.icon-card::before {
  content: '\ea0f';
}

.icon-cardContactless::before {
  content: '\ea10';
}

.icon-chart::before {
  content: '\ea11';
}

.icon-charts::before {
  content: '\ea12';
}

.icon-check::before {
  content: '\ea13';
}

.icon-checkCircle::before {
  content: '\ea14';
}

.icon-checkCircleFilled::before {
  content: '\ea15';
}

.icon-checklist::before {
  content: '\ea16';
}

.icon-chrono::before {
  content: '\ea17';
}

.icon-close::before {
  content: '\ea18';
}

.icon-connect::before {
  content: '\ea19';
}

.icon-contact::before {
  content: '\ea1a';
}

.icon-csv::before {
  content: '\ea1b';
}

.icon-delegationFrom::before {
  content: '\ea1c';
}

.icon-delegationTo::before {
  content: '\ea1d';
}

.icon-detach::before {
  content: '\ea1e';
}

.icon-diners::before {
  content: '\ea1f';
}

.icon-discover::before {
  content: '\ea20';
}

.icon-distribute::before {
  content: '\ea21';
}

.icon-dots::before {
  content: '\ea22';
}

.icon-down::before {
  content: '\ea23';
}

.icon-dragIndicator::before {
  content: '\ea24';
}

.icon-elo::before {
  content: '\ea25';
}

.icon-empty::before {
  content: '\ea26';
}

.icon-envelope::before {
  content: '\ea27';
}

.icon-export::before {
  content: '\ea28';
}

.icon-eye::before {
  content: '\ea29';
}

.icon-eyeOff::before {
  content: '\ea2a';
}

.icon-file::before {
  content: '\ea2b';
}

.icon-filter::before {
  content: '\ea2c';
}

.icon-flight::before {
  content: '\ea2d';
}

.icon-freeze::before {
  content: '\ea2e';
}

.icon-galleryOutlined::before {
  content: '\ea2f';
}

.icon-grid::before {
  content: '\ea30';
}

.icon-gridOutlined::before {
  content: '\ea31';
}

.icon-guest::before {
  content: '\ea32';
}

.icon-hiper::before {
  content: '\ea33';
}

.icon-hipercard::before {
  content: '\ea34';
}

.icon-home::before {
  content: '\ea35';
}

.icon-hotel::before {
  content: '\ea36';
}

.icon-hourglass::before {
  content: '\ea37';
}

.icon-idCard::before {
  content: '\ea38';
}

.icon-import::before {
  content: '\ea39';
}

.icon-info::before {
  content: '\ea3a';
}

.icon-infoWarn::before {
  content: '\ea3b';
}

.icon-integration::before {
  content: '\ea3c';
}

.icon-jcb::before {
  content: '\ea3d';
}

.icon-justify::before {
  content: '\ea3e';
}

.icon-key::before {
  content: '\ea3f';
}

.icon-launch::before {
  content: '\ea40';
}

.icon-layout::before {
  content: '\ea41';
}

.icon-left::before {
  content: '\ea42';
}

.icon-link::before {
  content: '\ea43';
}

.icon-list::before {
  content: '\ea44';
}

.icon-listOutlined::before {
  content: '\ea45';
}

.icon-location::before {
  content: '\ea46';
}

.icon-lock::before {
  content: '\ea47';
}

.icon-login::before {
  content: '\ea48';
}

.icon-logout::before {
  content: '\ea49';
}

.icon-loyaltyAir::before {
  content: '\ea4a';
}

.icon-loyaltyCar::before {
  content: '\ea4b';
}

.icon-loyaltyHotel::before {
  content: '\ea4c';
}

.icon-loyaltyProgram::before {
  content: '\ea4d';
}

.icon-loyaltyTrain::before {
  content: '\ea4e';
}

.icon-luggage::before {
  content: '\ea4f';
}

.icon-maestro::before {
  content: '\ea50';
}

.icon-magic::before {
  content: '\ea51';
}

.icon-mail::before {
  content: '\ea52';
}

.icon-mastercard::before {
  content: '\ea53';
}

.icon-mileageVehicle::before {
  content: '\ea54';
}

.icon-minusCircle::before {
  content: '\ea55';
}

.icon-mir::before {
  content: '\ea56';
}

.icon-noimage::before {
  content: '\ea57';
}

.icon-organization::before {
  content: '\ea58';
}

.icon-passport::before {
  content: '\ea59';
}

.icon-paymentMethods::before {
  content: '\ea5a';
}

.icon-pdf::before {
  content: '\ea5b';
}

.icon-pencil::before {
  content: '\ea5c';
}

.icon-pin::before {
  content: '\ea5d';
}

.icon-plus::before {
  content: '\ea5e';
}

.icon-plusCircle::before {
  content: '\ea5f';
}

.icon-process::before {
  content: '\ea60';
}

.icon-purchase::before {
  content: '\ea61';
}

.icon-questionCircle::before {
  content: '\ea62';
}

.icon-rail::before {
  content: '\ea63';
}

.icon-receipts::before {
  content: '\ea64';
}

.icon-reconciliation::before {
  content: '\ea65';
}

.icon-refresh::before {
  content: '\ea66';
}

.icon-restore::before {
  content: '\ea67';
}

.icon-review::before {
  content: '\ea68';
}

.icon-right::before {
  content: '\ea69';
}

.icon-save::before {
  content: '\ea6a';
}

.icon-search::before {
  content: '\ea6b';
}

.icon-settings::before {
  content: '\ea6c';
}

.icon-showDetails::before {
  content: '\ea6d';
}

.icon-spend::before {
  content: '\ea6e';
}

.icon-spinner::before {
  content: '\ea6f';
}

.icon-switch::before {
  content: '\ea70';
}

.icon-sync::before {
  content: '\ea71';
}

.icon-telephone::before {
  content: '\ea72';
}

.icon-trash::before {
  content: '\ea73';
}

.icon-travelDoc::before {
  content: '\ea74';
}

.icon-unionpay::before {
  content: '\ea75';
}

.icon-unlink::before {
  content: '\ea76';
}

.icon-up::before {
  content: '\ea77';
}

.icon-upload::before {
  content: '\ea78';
}

.icon-user::before {
  content: '\ea79';
}

.icon-userPlus::before {
  content: '\ea7a';
}

.icon-users::before {
  content: '\ea7b';
}

.icon-visa::before {
  content: '\ea7c';
}

.icon-wallet::before {
  content: '\ea7d';
}

.icon-warning::before {
  content: '\ea7e';
}

.icon-warningFilled::before {
  content: '\ea7f';
}
